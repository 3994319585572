//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import upFile from './components/file.vue'
import upBath from './components/bath.vue'
import upClean from './components/clean.vue'
import upFeed from './components/feedingRecord.vue'
import upWs from './components/weishi.vue'
import { pigInfo, getToken, ranchData } from '../../common/api'
import { WebView } from '../../common/app'
import EZUIKit from 'ezuikit-js'
import Utils from '../../util/index'
export default {
  components: {
    upFile,
    upFeed,
    upBath,
    upWs,
    upClean,
  },
  data() {
    return {
      name: '小灰灰',
      fileShow: false, //档案弹窗
      sucessShow: false, // 喂养成功弹窗
      errorShow: false, //时间未到
      feedShow: false, //喂养记录弹窗
      bathShow: false, // 洗澡弹窗
      wsShow: false, //喂食弹窗
      treatShow: false, //治疗弹窗
      cleanShow: false, //清扫
      erroeShow: false,
      message: '',
      pigDetail: {},
      pigList: [], // 猪崽集合
      url: '', //地址
      accessToken: '', //萤石云token
      timing: 0,
      networkAnomaly: false,
      timer: null,
      isShow: false,
    }
  },
  created() {
    if (
      /Safari/.test(navigator.userAgent) &&
      !/Chrome/.test(navigator.userAgent)
    ) {
      console.log('Safari')
      if (this.$store.state.access_id) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    } else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      console.log('进入ios')
      if (this.$store.state.access_id) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    } else {
      console.log('进入安卓')
      this.isShow = true
    }
  },
  mounted() {
    let that = this
    WebView.getPidAndAccessid = this.getPidAndAccessid
    console.log('数据', this.getPidAndAccessid)
    // this.getPidAndAccessid()

    // that.getDetail()
    Utils.$on('getToken', () => {
      that.getToken()
    })
  },
  methods: {
    // 开关
    async ranch() {
      let res = await ranchData()
      console.log(res.data.data.ranch_switch)
      let num = res.data.data.ranch_switch
      if (
        /Safari/.test(navigator.userAgent) &&
        !/Chrome/.test(navigator.userAgent)
      ) {
        console.log('Safari')
        if (num == 0) {
          this.isShow = false
        } else {
          this.isShow = true
        }
      }

      // /(iPhone|iPad|iPod|iOS|Safari)/i.test(navigator.userAgent)
      else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        if (num == 0) {
          this.isShow = false
        } else {
          this.isShow = true
        }
      } else {
        this.isShow = true
      }
    },
    getPidAndAccessid(pidAndAccessId) {
      console.log('tokn')
      console.log('传过来的数据', pidAndAccessId)
      let all = pidAndAccessId.split('And')
      const accessId = all[1]
      const pid = all[0]
      // const accessId =
      //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NTA5NjI1NDgsImV4cCI6MTY1MzU1NDU0OCwianRpIjoiNzQ4NDVhMmJlNTBiYmI2MDJhYjA3ZjRkNzg5MDU5MmEifQ.MENYQbic6o3xI0vo3C_t9eh_XEXxXOcbWe6IsTqEzr8'
      // const pid = '161'
      this.$store.commit('SET_ACCESS_ID', accessId)
      this.$store.commit('SET_P_ID', pid)

      this.getDetail()

      setTimeout(() => {
        this.getToken()
      }, 500)
    },
    //左滑动
    onSwipeLeft: function () {
      this.findIndex(this.$store.state.p_id, 1)
      this.changeUrl()
    },
    //右滑动
    onSwipeRight: function () {
      this.findIndex(this.$store.state.p_id, 2)
      this.changeUrl()
    },
    changeUrl() {
      this.player.stop().then(() => {
        this.player.play(this.url)
      })
    },
    findIndex(id, type) {
      const num = this.pigList.findIndex((ele) => {
        return id == ele
      })
      if (type == 2) {
        if (num == 0) {
          this.message = '到头了！'
          this.erroeShow = !this.erroeShow
          setTimeout(() => {
            this.getDetail()
            this.erroeShow = !this.erroeShow
          }, 1000)
          return
        }
        this.$store.commit('SET_P_ID', this.pigList[num - 1])
      } else {
        if (num == this.pigList.length - 1) {
          this.message = '已经没有了！'
          this.erroeShow = !this.erroeShow
          setTimeout(() => {
            this.getDetail()
            this.erroeShow = !this.erroeShow
          }, 1000)
          return
        }
        this.$store.commit('SET_P_ID', this.pigList[num + 1])
      }
      this.message = '切换成功！'
      this.erroeShow = !this.erroeShow
      setTimeout(() => {
        this.getDetail()
        this.erroeShow = !this.erroeShow
      }, 1000)
    },
    Iknowledge() {
      this.getDetail()
      this.sucessShow = !this.sucessShow
    },
    knowledge() {
      this.getDetail()
      this.errorShow = !this.errorShow
    },
    //猪崽详情
    async getDetail() {
      let data = {
        access_id: this.$store.state.access_id,
        ls_id: this.$store.state.p_id,
      }
      let res = await pigInfo(data)
      if (res.data.code != 200) return

      this.pigDetail = res.data.data
      this.pigList = res.data.data.pig_list
      this.url = res.data.data.monitor_url
      this.$store.commit('SET_NICKNAME', res.data.data.nickname)
    },
    // 视频初始化
    async getToken() {
      // 发送 POST 请求
      console.log('进来')
      const res = await getToken()
      if (res.data.code != 200) return
      this.accessToken = res.data.data.accessToken
      console.log(this.accessToken)
      //视频初始化
      console.log('数据999999', res)
      console.log(this.url, 'this.urlthis.url')
      this.player = await new EZUIKit.EZUIKitPlayer({
        autoplay: true, //自动播放
        id: 'video-container', // 视频容器ID
        accessToken: this.accessToken,
        url: this.url,
        audio: 0,
      })
      this.timer = setInterval(() => {
        this.timing += 60000
        if (this.timing >= 600000) {
          this.stopVideo()
          console.log('结束播放')
          this.networkAnomaly = true
          clearInterval(this.timer)
          return
        }
      }, 60000)
    },
    // 停止播放
    stopVideo() {
      this.player.stop()
    },
    // 退出
    tuichu() {
      console.log('点击关闭')
      window.location.href = 'uniwebview://closeFeed?test=closeFeed'
    },
    close() {
      console.log('点击关闭')
      window.location.href = 'uniwebview://closeFeed?test=closeFeed'
    },
    // 去分享
    goShare() {
      window.location.href = 'uniwebview://share?lsId=' + this.$store.state.p_id
    },
    success() {
      this.wsShow = false
      this.sucessShow = !this.sucessShow
    },
    error() {
      this.wsShow = false
      this.errorShow = !this.errorShow
    },
    //关闭治疗
    closeTreat() {
      this.treatShow = false
    },
    // 关闭喂食弹窗
    closeWs(val) {
      this.wsShow = val
    },
    // 关闭档案弹窗
    closeDa(val) {
      this.fileShow = val
    },
    // 关闭洗澡弹窗
    closeXz(val) {
      this.bathShow = val
    },
    // 关闭清扫 弹窗
    closeQs(val) {
      this.cleanShow = val
    },
    closeJl(val) {
      this.feedShow = val
    },
    // 喂食弹窗
    Ws() {
      this.$refs.Ws.clickInit()
      this.wsShow = true
    },
    // 清扫弹窗
    Qs() {
      this.cleanShow = true
      this.$refs.Qs.clickInit()
    },
    // 洗澡弹窗
    Xz() {
      this.bathShow = true
      this.$refs.Xz.clickInit()
    },
    Jl() {
      this.$refs.Jl.clickInit()
      this.feedShow = true
    },
    Da() {
      this.$refs.Da.clickInit()
      this.fileShow = true
    },
  },
}
