//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { feedFood, feedMeal, feed } from "../../../common/api";
export default {
  props: {
    wsShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: 0,
      show: true,
      foodList: [],
      mealList: [],
      select: [],
      isClick: false,
      erroeShow: false,
      message: "",
      timeout: 2000, //展示时间
      jitter: true, // 抖动
      foods: {
        shui: false,
        msg: "",
      },
    };
  },
  created() {},
  methods: {
    clickInit() {
      this.food();
    },
    async mealFeed(id) {
      this.isClick = true;
      let val = {
        access_id: this.$store.state.access_id,
        ls_id: this.$store.state.p_id,
        meal_id: id,
      };
      const res = await feed(val);
      console.log(res.data.code, "resres");
      if (res.data.code == 200) {
        this.$emit("success");
        this.isClick = false;
      } else {
        this.message = res.data.message;
        this.erroeShow = !this.erroeShow;
        this.isClick = false;
        setTimeout(() => {
          this.erroeShow = !this.erroeShow;
        }, this.timeout);
      }
    },
    //食物列表
    async food() {
      let data = {
        access_id: this.$store.state.access_id,
        ls_id: this.$store.state.p_id,
        type: "1",
      };
      let res = await feedFood(data);
      if (res.data.code == "40004") {
        this.foodList = res.data.data;
      }
      if (res.data.code == "200") {
        this.foodList = res.data.data;
      }
      let data1 = {
        access_id: this.$store.state.access_id,
        ls_id: this.$store.state.p_id,
      };
      let meal = await feedMeal(data1);
      if (meal.data.code == "40004") {
        this.mealList = meal.data.data;
      }
      if (meal.data.code == "200") {
        this.mealList = meal.data.data;
      }
    },
    // 去喂食
    async goFeed() {
      this.isClick = true;
      let food = this.select.map((res) => {
        return res.pro_id + ":" + res.left_copies;
      });
      let pro = food.join("#");
      let data = {
        access_id: this.$store.state.access_id,
        ls_id: this.$store.state.p_id,
        pro_id: pro,
      };
      const res = await feed(data);
      console.log(res, "resres");
      if (res.data.code == "70003" || res.data.code == "70004") {
        this.foods.shui = true;
        this.foods.msg = res.data.message;
        this.jitter = true;
        this.isClick = false;
        setTimeout(() => {
          this.jitter = false;
        }, 600);
        return;
      }
      if (res.data.code == "800001") {
        this.$emit("error");
        this.isClick = false;
        return;
      }
      if (res.data.code == 200) {
        this.select = [];
        this.$emit("success");
        this.isClick = false;
      } else {
        this.message = res.data.message;
        this.erroeShow = !this.erroeShow;
        this.isClick = false;
        setTimeout(() => {
          this.erroeShow = !this.erroeShow;
        }, this.timeout);
      }
    },
    // 使用
    Use(data, val) {
      if (data.cate_id == 1 || data.cate_id == 4) {
        this.foods.shui = false;
      }
      if (this.foodList[val].left_copies > 0) {
        this.foodList[val].left_copies -= 1;
        if (this.select.length == 0) {
          this.select.push(JSON.parse(JSON.stringify(data)));
          this.select[0].left_copies = 1;
        } else {
          let proid = this.select.findIndex((item) => {
            return item.pro_id == data.pro_id;
          });
          if (proid == -1) {
            if (this.select.length == 4) {
              this.message = "只能选四种食物哦~";
              this.erroeShow = !this.erroeShow;
              setTimeout(() => {
                this.erroeShow = !this.erroeShow;
              }, this.timeout);
            } else {
              this.select.push(JSON.parse(JSON.stringify(data)));
              this.select[this.select.length - 1].left_copies = 1;
            }
          } else {
            this.select[proid].left_copies++;
          }
        }
      } else {
        this.message = "已空，请选择别的食物";
        this.erroeShow = !this.erroeShow;
        setTimeout(() => {
          this.erroeShow = !this.erroeShow;
        }, this.timeout);
      }
    },
    //删除
    remove(val, index) {
      console.log(val);
      this.foodList.forEach((res) => {
        if (val.pro_id == res.pro_id) {
          res.left_copies += val.left_copies;
        }
      });
      this.select.splice(index, 1);
    },
    goBuy() {
      console.log("去购买");
      window.location.href = "uniwebview://goBuy?test=goBuy";
    },
    close() {
      this.$emit("closeWs", false);
      this.select = [];
      this.foods.shui = false;
      this.food();
    },
  },
};
