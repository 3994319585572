//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import luxiang from "../components/luxiang.vue";
import { record } from "../../../common/api";
export default {
  components: {
    luxiang,
  },
  props: {
    feedShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      feedList: [],
      lushow: false,
      isNull: false,
      video_url: "",
    };
  },

  methods: {
    clickInit() {
      this.recordJl();
    },
    lookLu(url) {
      this.video_url = url;
      this.lushow = true;
    },
    //喂养记录
    async recordJl() {
      let data = {
        access_id: this.$store.state.access_id,
        ls_id: this.$store.state.p_id,
        page: 1,
        days: 3,
      };
      let res = await record(data);
      console.log(res, "喂养记录");
      if (res.data.code == "4004") {
        this.isNull = true;
        return;
      }
      if (res.data.code != 200) return;
      this.feedList = res.data.data;
    },
    close() {
      this.$emit("closeJl", false);
    },
    closeLu(val) {
      this.lushow = val;
    },
  },
};
