//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { pigFiles, record } from "../../../common/api";
import updateName from "./updateName.vue";
export default {
  components: { updateName },
  props: {
    fileShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: 1,
      pigDetal: {},
      feedList: [],
      isNull: false,
      nameshow: false,
    };
  },
  methods: {
    clickInit() {
      this.initDetail();
      this.recordJl();
    },
    changeNameShow(val) {
      this.nameshow = val;
    },
    //获取猪崽档案
    async initDetail() {
      let data = {
        access_id: this.$store.state.access_id,
        p_id: this.$store.state.p_id,
      };
      let res = await pigFiles(data);
      if (res.data.code != 200) return;
      console.log(res.data.data, "档案");
      this.pigDetal = res.data.data;
      this.$store.commit("SET_NICKNAME", res.data.data.nickname);
    },

    //喂养记录
    async recordJl() {
      let data = {
        access_id: this.$store.state.access_id,
        ls_id: this.$store.state.p_id,
        page: 1,
        days: 3,
      };
      let res = await record(data);
      console.log(res, "喂养记录");
      if (res.data.code == "4004") {
        console.log(1);
        this.isNull = true;
        return;
      }
      if (res.data.code != "200") return;
      this.feedList = res.data.data;
      console.log(this.feedList, "this.feedList");
    },
    close() {
      this.$emit("closeDa", false);
    },
  },
};
