//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { feedFood, wash } from "../../../common/api";

export default {
  props: {
    bathShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeName: "a",
      condition: false,
      bathList: [],
      sucessShow: false,
      isClick: false,
      name: "",
    };
  },
  methods: {
    clickInit() {
      this.bath();
    },
    async Use(id, name) {
      this.isClick = true;
      let data = {
        access_id: this.$store.state.access_id,
        ls_id: this.$store.state.p_id,
        pro_id: id,
      };
      const res = await wash(data);
      if (res.data.code != 200) {
        this.isClick = false;
        this.$toast(res.data.message);
        return;
      }
      this.sucessShow = !this.sucessShow;
      this.isClick = false;
      this.name = name;
    },
    knowledge() {
      this.sucessShow = !this.sucessShow;
      this.close();
    },
    close() {
      this.$emit("closeXz", false);
      this.bath();
    },
    goBuy() {
      console.log("去购买");
      window.location.href = "uniwebview://goBuy?test=goBuy";
    },
    //洗澡列表
    async bath() {
      let data = {
        access_id: this.$store.state.access_id,
        ls_id: this.$store.state.p_id,
        type: "4",
      };
      let res = await feedFood(data);
      if (res.data.code == "40004") {
        this.bathList = res.data.data;
        console.log(this.bathList, " this.bathList ");
      }
      if (res.data.code == "200") {
        this.bathList = res.data.data;
      }
    },
  },
};
